<script setup lang="ts">
import type { NovaHeaderSearchBarEmits } from './NovaHeaderSearchBar.types'
import { ROUTES } from '@configs'

const emits = defineEmits<NovaHeaderSearchBarEmits>()
const route = useRoute()
const { t } = useI18n()
const searchStore = useSearchStore()
const keyword = ref('')
const isSearchPage = computed(() => {
  const routeName = useGetRouteName()
  return routeName.includes(ROUTES.SEARCH.name)
})
const { gtEvent } = useGoogleTag()

const handleOnSearch = async () => {
  if (!keyword.value || keyword.value.match(/^ *$/) !== null) {
    keyword.value = ''
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '헤더 > 검색 > 검색어 미입력시 Toast 노출',
      eventLabel: t('statusMessages.searchKeyword.empty'),
      eventSlot: '헤더',
      eventI18nAddr: useKoreanTranslation('statusMessages.searchKeyword.empty'),
      eventComponent: 'Input',
    })
    useToast(t('statusMessages.searchKeyword.empty'))
    return
  }
  const trim = keyword.value.trim()
  searchStore.updateKeyword(trim)
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: '헤더 > 검색',
    eventLabel: 'search',
    eventSlot: '헤더',
    eventI18nAddr: 'search',
    eventComponent: 'Input',
    searchContents: trim,
  })
  await useNavigations({ url: ROUTES.SEARCH.path, query: { keyword: trim } })
  emits('onSearch')
}

watch(
  () => route.name,
  () => {
    keyword.value = isSearchPage.value ? searchStore.keyword : ''
  },
  { immediate: true }
)
</script>

<template>
  <NovaInput
    v-model="keyword"
    :placeholder="t('searchPlaceholder')"
    :type="'search'"
    theme="search"
    @on-focus="() => emits('onFocus')"
    @on-blur="() => emits('onBlur')"
    @on-search="handleOnSearch"
  >
    <template #tools>
      <NovaButtonIcon
        :icon="{ type: 'outline', name: 'search' }"
        class="search-button"
        @click="handleOnSearch"
      />
    </template>
  </NovaInput>
</template>

<style scoped lang="scss"></style>
