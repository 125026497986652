<script setup lang="ts">
import type {
  NovaHeaderNavgationsItem,
  NavigationCode,
} from './NovaHeaderNavgationsItem.types'
import { ROUTES } from '@configs'

const { t } = useI18n()
const { gtEvent } = useGoogleTag()
const postStore = usePostStore()
const { posts } = storeToRefs(postStore)
const onTarget = computed<NavigationCode>(() => {
  const name = useGetRouteName()
  switch (name) {
    case ROUTES.HOME.name:
    case ROUTES.CREATORS_MY.name:
    case ROUTES.CREATORS_ALL.name:
      return 'home'
    case ROUTES.FEED.name:
    case ROUTES.FEED_DETAIL.name:
      return 'feed'
    case ROUTES.FUND.name:
    case ROUTES.FUND_DETAIL.name:
    case ROUTES.FUND_NOTICE.name:
    case ROUTES.FUND_NOTICE_DETAIL.name:
    case ROUTES.FUND_GAUDI.name:
    case ROUTES.FUND_HISTORY.name:
    case ROUTES.FUND_HISTORY_DETAIL.name:
    case ROUTES.FUND_GAUDI_FUND.name:
      return 'fund'
    case ROUTES.NOVAPLUS.name:
    case ROUTES.NOVAPLUS_DETAIL.name:
      return 'novaPlus'
    default:
      return 'unknown'
  }
})
const navigations = computed<NovaHeaderNavgationsItem[]>(() => {
  const arr = [
    {
      id: 'home',
      name: t('navigations.home'),
      on: onTarget.value === 'home',
      go: () => {
        useNavigations({ url: ROUTES.HOME.path })
        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: '헤더 네비게이션 > 페이지 이동',
          eventLabel: t('navigations.home'),
          eventSlot: '홈, 피드, 펀드, 노바+',
          eventI18nAddr: useKoreanTranslation('navigations.home'),
          eventComponent: 'SwiperSlide',
        })
      },
    },
    {
      id: 'feed',
      name: t('navigations.feed'),
      on: onTarget.value === 'feed',
      go: () => {
        const query = useGetFeedQuery()

        if (posts.value.items.length && posts.value.payload.cmtyNttCtgrySn) {
          useNavigations({
            url: ROUTES.FEED.path,
            query,
            type: 'replace',
          })
        } else {
          useNavigations({ url: ROUTES.FEED.path, query })
        }

        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: '헤더 네비게이션 > 페이지 이동',
          eventLabel: t('navigations.feed'),
          eventSlot: '홈, 피드, 펀드, 노바+',
          eventI18nAddr: useKoreanTranslation('navigations.feed'),
          eventComponent: 'SwiperSlide',
        })
      },
    },
    // TODO: 앱 버전 릴리즈를 위해 기능 숨김
    // {
    //   id: 'novaPlus',
    //   name: t('subscribe.premiumBrand'),
    //   on: onTarget.value === 'novaPlus',
    //   go: () => {
    //     useNavigations({ url: ROUTES.NOVAPLUS.path })
    //     gtEvent('clickEvent', {
    //       eventCategory: '클릭',
    //       eventAction: '헤더 네비게이션 > 페이지 이동',
    //       eventLabel: t('subscribe.subscribeGrade.novaPlus'),
    //       eventSlot: '홈, 피드, 펀드, 노바+',
    //       eventI18nAddr: useKoreanTranslation(
    //         'subscribe.subscribeGrade.novaPlus'
    //       ),
    //       eventComponent: 'SwiperSlide',
    //     })
    //   },
    // },
  ]

  return arr as NovaHeaderNavgationsItem[]
})
</script>

<template>
  <div class="navigations">
    <button
      v-for="item in navigations"
      :key="item.id"
      type="button"
      :class="['navigation-item', { on: item.on }]"
      @click="item.go"
    >
      <span v-dompurify-html="item.name" class="label" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.navigations {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  height: 100%;

  @include mobile {
    gap: 24px;
  }

  .navigation-item {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;

    > .label {
      color: $color-text-4;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.45px;

      @include mobile {
        font-size: 16px;
      }
    }

    &.on {
      > .label {
        color: #111;
        font-weight: 700;
      }
    }
  }
}
</style>
